import React, { useRef, useState } from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const Accordion = ({ question }) => {
  const [accordion, setAccordion] = useState("");
  const [height, setHeight] = useState("0px");
  const content = useRef(null);

  function toggleAccordion() {
    setAccordion(accordion === "" ? " active" : "");
    setHeight(
      accordion === " active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }

  return (
    <div className={`faq-accordion${accordion}`}>
      <button
        onClick={toggleAccordion}
        onFocus={() =>
          setTimeout(() => {
            toggleAccordion();
          }, 150)
        }
      >
        <h3>{question.question}</h3>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.05849 11.0573C7.57919 10.5366 8.42341 10.5366 8.94411 11.0573L16.0013 18.1145L23.0585 11.0573C23.5792 10.5366 24.4234 10.5366 24.9441 11.0573C25.4648 11.578 25.4648 12.4222 24.9441 12.9429L16.9441 20.9429C16.4234 21.4636 15.5792 21.4636 15.0585 20.9429L7.05849 12.9429C6.53779 12.4222 6.53779 11.578 7.05849 11.0573Z"
            fill="#0B2246"
          />
        </svg>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="faq-accordion-content"
      >
        {renderRichText(question.answer)}
      </div>
    </div>
  );
};

export default Accordion;
