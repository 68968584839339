import React from "react";

import Accordion from "./accordion"

const FaqSection = ({ title, questions }) => (
  <section className="section section-gradient">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <h2 className="mb-12 mb-md-16">{title}</h2>
          <div className="faq-group">
            {questions.map((question) => (
              <Accordion question={question} key={question.contentful_id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FaqSection;